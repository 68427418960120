import request from './base/request';
const URL_SETTING = 'numeral'


export const getSettingFormatNumber = (params) => {
  return request
    .get(`${URL_SETTING}`, { params })
    .then((res) => {
      // TODO
      // store.dispatch('settingApp/actionSettingNumber', { setting: settingFormatNumber })
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const updateSettingFormatNumber = (params) => {
  return request
    .put(URL_SETTING, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}

