<template>
  <div class="settings">
    <div class="settings__header">
      <page-title :title="$t('setting_format_number.title_setting_format_number')" />
    </div>
    <div class="settings__body">
      <div class="settings__body-block--center" :class="$i18n.locale">
        <div class="settings__body-block--center__frame">
          <div class="settings__body-block--center__frame--title">{{
            $t('setting_format_number.title_setting_decimal_section') }}</div>
          <div class="settings__body-block--center__frame--des" v-html="$t('setting_format_number.description_setting_decimal_section')"></div>
          <div class="settings__body-block--center__frame--option">
            <p>{{ $t('setting_format_number.setting_decimal_section_co2') }}</p>
            <RadioSelect :isCustomDirectionSmp="true" v-model="valueDecimalCo2" :defaultValue="valueDecimalCo2" :items="items" />
          </div>
          <div class="settings__body-block--center__frame--option">
            <p>{{ $t('setting_format_number.setting_decimal_section_percen') }}</p>
            <RadioSelect :isCustomDirectionSmp="true" v-model="valueDecimalPercentage" :defaultValue="valueDecimalPercentage" :items="items" />
          </div>
          <div class="settings__body-block--center__frame--option">
            <p>{{ $t('setting_format_number.setting_decimal_section_number_value') }}</p>
            <RadioSelect :isCustomDirectionSmp="true" v-model="valueDecimalCurrency" :defaultValue="valueDecimalCurrency" :items="items" />
          </div>
        </div>
        <div class="settings__body-block--center__frame">
          <div class="settings__body-block--center__frame--title">{{
            $t('setting_format_number.title_setting_digits_section') }}</div>
          <div class="settings__body-block--center__frame--des" v-html="$t('setting_format_number.description_setting_digits_section')"></div>
          <div class="settings__body-block--center__frame--option">
            <p>{{ $t('setting_format_number.setting_digits_section_thousands_separator') }}</p>
            <RadioSelect :isCustomDirection="true" v-model="valueDigits" :defaultValue="valueDigits"
              :items="itemDigits" />
          </div>
        </div>
        <input class="settings__body-block--center__btn" type="button"
          :value="$t('setting_format_number.button_submit_setting_format_number')"
          @click="handleUpdateSettingFormatNumber" />
      </div>
    </div>
    <notification-popup :dialog="dialogNotification" :message="$t('auto_approval.message_saved_changes')"
      @submit="dialogNotification = false" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROLE } from '@/constants/role';
import { ROUTES } from '@/router/constants';

import PageTitle from '@/components/pageTitle/pageTitle.vue';
import RadioSelect from "@/components/commonApp/RadioCustom/index.vue";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";

import { getSettingFormatNumber, updateSettingFormatNumber } from '@/api/settingFormatNumber'

export default {
  name: 'settingFormatNumber',
  components: { PageTitle, RadioSelect, NotificationPopup },
  data() {
    return {
      breadcrumb:[
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('setting.title_setting'),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t('setting_format_number.title_setting_format_number'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_AUTO_APPROVAL}`,
        },
      ],
      items: [
        {
          id: 2,
          name: this.$t('setting_format_number.setting_decimal_section_option1')
        },
        {
          id: 1,
          name: this.$t('setting_format_number.setting_decimal_section_option2')
        },
        {
          id: 0,
          name: this.$t('setting_format_number.setting_decimal_section_option3')
        }
      ],
      itemDigits: [
        {
          id: 0,
          name: this.$t('setting_format_number.setting_digits_section_option1')
        },
        {
          id: 1,
          name: this.$t('setting_format_number.setting_digits_section_option2')
        },
        {
          id: 2,
          name: this.$t('setting_format_number.setting_digits_section_option3')
        },
        {
          id: 3,
          name: this.$t('setting_format_number.setting_digits_section_option4')
        }
      ],
      valueDecimalCo2: 2,
      valueDecimalPercentage: 2,
      valueDecimalCurrency: 2,
      valueDigits: 0,
      dialogNotification: false
    }
  },
  computed: {
    isProductBranch() {
      return this.$route.name === 'ProductSettingFormatNumber';
    },
    ...mapState('userData', ['currentUser']),
  },
  async mounted() {
    if (this.isProductBranch) {
      this.breadcrumb = [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
        {
          text: this.$t('setting.title_setting'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING,
        },
        {
          text: this.$t('setting_format_number.title_setting_format_number'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING_DISPLAY_FORMAT_NUMBER,
        },
      ];
    } else {
      this.breadcrumb = [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('setting.title_setting'),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t('setting_format_number.title_setting_format_number'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING_DISPLAY_FORMAT_NUMBER,
        },
      ];
    }

    this.updateBreadCrumb(this.breadcrumb);
    await this.handleGetSettingFormatNumber()
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    async handleUpdateSettingFormatNumber() {
      try {
        const payload = {
          co2_decimal_precision: this.valueDecimalCo2,
          percentage_decimal_precision: this.valueDecimalPercentage,
          currency_decimal_precision: this.valueDecimalCurrency,
          thousand_separator: this.valueDigits
        }
        await updateSettingFormatNumber(payload)
        this.dialogNotification = true
      } catch (error) {
        console.warn(error);
      }
    },
    async handleGetSettingFormatNumber() {
      try {
        const res = await getSettingFormatNumber();
        const setting = res.data;
        this.valueDecimalCo2 = setting.co2_decimal_precision,
        this.valueDecimalPercentage = setting.percentage_decimal_precision,
        this.valueDecimalCurrency = setting.currency_decimal_precision,
        this.valueDigits = setting.thousand_separator
      } catch (error) {
        console.warn(error);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.settings {
  &__header {
    display: flex;
    padding: 0px 0px 32px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
  }
  &__body {
    &-block {
      &--center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 56px;
        margin: 0px -4px;

        &__frame {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;

          &--title {
            color: $goldMid;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            /* 175% */
            letter-spacing: 1.6px;
          }

          &--des {
            color: $monoBlack;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 186.667% */
            letter-spacing: 0.75px;
          }

          &--option {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            p {
              color: $monoBlack;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.42px;
              margin-bottom: 0px;
            }
          }
        }
        &__btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 2px 20px;
          background: $goldMid;
          width: 100%;
          height: 56px;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;
          color: $monoWhite;

          &:hover {
            background: $goldLight;
            box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
              0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
          }

          &:focus-within {
            border: 2px solid $blueMid;
            padding: 14px 0px 18px;
            color: $monoWhite;
          }
        }
      }
    }
  }
}

@include desktop {
  .settings {
      &__header {
        padding: 40px 40px 32px 40px;
      }
  
      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 48px;
        align-self: stretch;
        &-block {
          &--center {
            width: 600px;
            &.en {
              width: 644px;
            }
          }
        }
      }
    }
}
</style>
